// Generated by CoffeeScript 1.12.7
(function() {
  var AudioDevice, EventEmitter, Resampler, WebAudioDevice,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  EventEmitter = require('../core/events');

  AudioDevice = require('../device');

  Resampler = require('./resampler');

  WebAudioDevice = (function(superClass) {
    var AudioContext, createProcessor, sharedContext;

    extend(WebAudioDevice, superClass);

    AudioDevice.register(WebAudioDevice);

    AudioContext = global.AudioContext || global.webkitAudioContext;

    WebAudioDevice.supported = AudioContext && (typeof AudioContext.prototype[createProcessor = 'createScriptProcessor'] === 'function' || typeof AudioContext.prototype[createProcessor = 'createJavaScriptNode'] === 'function');

    sharedContext = null;

    function WebAudioDevice(sampleRate, channels1) {
      this.sampleRate = sampleRate;
      this.channels = channels1;
      this.refill = bind(this.refill, this);
      this.context = sharedContext != null ? sharedContext : sharedContext = new AudioContext;
      this.deviceSampleRate = this.context.sampleRate;
      this.bufferSize = Math.ceil(4096 / (this.deviceSampleRate / this.sampleRate) * this.channels);
      this.bufferSize += this.bufferSize % this.channels;
      if (this.deviceSampleRate !== this.sampleRate) {
        this.resampler = new Resampler(this.sampleRate, this.deviceSampleRate, this.channels, this.bufferSize);
      }
      this.node = this.context[createProcessor](4096, this.channels, this.channels);
      this.node.onaudioprocess = this.refill;
      this.node.connect(this.context.destination);
    }

    WebAudioDevice.prototype.refill = function(event) {
      var channelCount, channels, data, i, j, k, l, n, outputBuffer, ref, ref1, ref2;
      outputBuffer = event.outputBuffer;
      channelCount = outputBuffer.numberOfChannels;
      channels = new Array(channelCount);
      for (i = j = 0, ref = channelCount; j < ref; i = j += 1) {
        channels[i] = outputBuffer.getChannelData(i);
      }
      data = new Float32Array(this.bufferSize);
      this.emit('refill', data);
      if (this.resampler) {
        data = this.resampler.resampler(data);
      }
      for (i = k = 0, ref1 = outputBuffer.length; k < ref1; i = k += 1) {
        for (n = l = 0, ref2 = channelCount; l < ref2; n = l += 1) {
          channels[n][i] = data[i * channelCount + n];
        }
      }
    };

    WebAudioDevice.prototype.destroy = function() {
      return this.node.disconnect(0);
    };

    WebAudioDevice.prototype.getDeviceTime = function() {
      return this.context.currentTime * this.sampleRate;
    };

    return WebAudioDevice;

  })(EventEmitter);

}).call(this);
