// Generated by CoffeeScript 1.12.7
(function() {
  var AUDemuxer, Demuxer,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  Demuxer = require('../demuxer');

  AUDemuxer = (function(superClass) {
    var bps, formats;

    extend(AUDemuxer, superClass);

    function AUDemuxer() {
      return AUDemuxer.__super__.constructor.apply(this, arguments);
    }

    Demuxer.register(AUDemuxer);

    AUDemuxer.probe = function(buffer) {
      return buffer.peekString(0, 4) === '.snd';
    };

    bps = [8, 8, 16, 24, 32, 32, 64];

    bps[26] = 8;

    formats = {
      1: 'ulaw',
      27: 'alaw'
    };

    AUDemuxer.prototype.readChunk = function() {
      var bytes, dataSize, encoding, size;
      if (!this.readHeader && this.stream.available(24)) {
        if (this.stream.readString(4) !== '.snd') {
          return this.emit('error', 'Invalid AU file.');
        }
        size = this.stream.readUInt32();
        dataSize = this.stream.readUInt32();
        encoding = this.stream.readUInt32();
        this.format = {
          formatID: formats[encoding] || 'lpcm',
          littleEndian: false,
          floatingPoint: encoding === 6 || encoding === 7,
          bitsPerChannel: bps[encoding - 1],
          sampleRate: this.stream.readUInt32(),
          channelsPerFrame: this.stream.readUInt32(),
          framesPerPacket: 1
        };
        if (this.format.bitsPerChannel == null) {
          return this.emit('error', 'Unsupported encoding in AU file.');
        }
        this.format.bytesPerPacket = (this.format.bitsPerChannel / 8) * this.format.channelsPerFrame;
        if (dataSize !== 0xffffffff) {
          bytes = this.format.bitsPerChannel / 8;
          this.emit('duration', dataSize / bytes / this.format.channelsPerFrame / this.format.sampleRate * 1000 | 0);
        }
        this.emit('format', this.format);
        this.readHeader = true;
      }
      if (this.readHeader) {
        while (this.stream.available(1)) {
          this.emit('data', this.stream.readSingleBuffer(this.stream.remainingBytes()));
        }
      }
    };

    return AUDemuxer;

  })(Demuxer);

}).call(this);
