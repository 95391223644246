// Generated by CoffeeScript 1.12.7
(function() {
  var AVBuffer, AudioDevice, EventEmitter, MozillaAudioDevice,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  EventEmitter = require('../core/events');

  AudioDevice = require('../device');

  AVBuffer = require('../core/buffer');

  MozillaAudioDevice = (function(superClass) {
    var createTimer, destroyTimer;

    extend(MozillaAudioDevice, superClass);

    AudioDevice.register(MozillaAudioDevice);

    MozillaAudioDevice.supported = (typeof Audio !== "undefined" && Audio !== null) && 'mozWriteAudio' in new Audio;

    function MozillaAudioDevice(sampleRate, channels) {
      this.sampleRate = sampleRate;
      this.channels = channels;
      this.refill = bind(this.refill, this);
      this.audio = new Audio;
      this.audio.mozSetup(this.channels, this.sampleRate);
      this.writePosition = 0;
      this.prebufferSize = this.sampleRate / 2;
      this.tail = null;
      this.timer = createTimer(this.refill, 100);
    }

    MozillaAudioDevice.prototype.refill = function() {
      var available, buffer, currentPosition, written;
      if (this.tail) {
        written = this.audio.mozWriteAudio(this.tail);
        this.writePosition += written;
        if (this.writePosition < this.tail.length) {
          this.tail = this.tail.subarray(written);
        } else {
          this.tail = null;
        }
      }
      currentPosition = this.audio.mozCurrentSampleOffset();
      available = currentPosition + this.prebufferSize - this.writePosition;
      if (available > 0) {
        buffer = new Float32Array(available);
        this.emit('refill', buffer);
        written = this.audio.mozWriteAudio(buffer);
        if (written < buffer.length) {
          this.tail = buffer.subarray(written);
        }
        this.writePosition += written;
      }
    };

    MozillaAudioDevice.prototype.destroy = function() {
      return destroyTimer(this.timer);
    };

    MozillaAudioDevice.prototype.getDeviceTime = function() {
      return this.audio.mozCurrentSampleOffset() / this.channels;
    };

    createTimer = function(fn, interval) {
      var url, worker;
      url = AVBuffer.makeBlobURL("setInterval(function() { postMessage('ping'); }, " + interval + ");");
      if (url == null) {
        return setInterval(fn, interval);
      }
      worker = new Worker(url);
      worker.onmessage = fn;
      worker.url = url;
      return worker;
    };

    destroyTimer = function(timer) {
      if (timer.terminate) {
        timer.terminate();
        return URL.revokeObjectURL(timer.url);
      } else {
        return clearInterval(timer);
      }
    };

    return MozillaAudioDevice;

  })(EventEmitter);

}).call(this);
