// Generated by CoffeeScript 1.12.7
(function() {
  var CAFDemuxer, Demuxer, M4ADemuxer,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  Demuxer = require('../demuxer');

  M4ADemuxer = require('./m4a');

  CAFDemuxer = (function(superClass) {
    extend(CAFDemuxer, superClass);

    function CAFDemuxer() {
      return CAFDemuxer.__super__.constructor.apply(this, arguments);
    }

    Demuxer.register(CAFDemuxer);

    CAFDemuxer.probe = function(buffer) {
      return buffer.peekString(0, 4) === 'caff';
    };

    CAFDemuxer.prototype.readChunk = function() {
      var buffer, byteOffset, cookie, entries, flags, i, j, k, key, metadata, offset, ref, ref1, sampleOffset, value;
      if (!this.format && this.stream.available(64)) {
        if (this.stream.readString(4) !== 'caff') {
          return this.emit('error', "Invalid CAF, does not begin with 'caff'");
        }
        this.stream.advance(4);
        if (this.stream.readString(4) !== 'desc') {
          return this.emit('error', "Invalid CAF, 'caff' is not followed by 'desc'");
        }
        if (!(this.stream.readUInt32() === 0 && this.stream.readUInt32() === 32)) {
          return this.emit('error', "Invalid 'desc' size, should be 32");
        }
        this.format = {};
        this.format.sampleRate = this.stream.readFloat64();
        this.format.formatID = this.stream.readString(4);
        flags = this.stream.readUInt32();
        if (this.format.formatID === 'lpcm') {
          this.format.floatingPoint = Boolean(flags & 1);
          this.format.littleEndian = Boolean(flags & 2);
        }
        this.format.bytesPerPacket = this.stream.readUInt32();
        this.format.framesPerPacket = this.stream.readUInt32();
        this.format.channelsPerFrame = this.stream.readUInt32();
        this.format.bitsPerChannel = this.stream.readUInt32();
        this.emit('format', this.format);
      }
      while (this.stream.available(1)) {
        if (!this.headerCache) {
          this.headerCache = {
            type: this.stream.readString(4),
            oversize: this.stream.readUInt32() !== 0,
            size: this.stream.readUInt32()
          };
          if (this.headerCache.oversize) {
            return this.emit('error', "Holy Shit, an oversized file, not supported in JS");
          }
        }
        switch (this.headerCache.type) {
          case 'kuki':
            if (this.stream.available(this.headerCache.size)) {
              if (this.format.formatID === 'aac ') {
                offset = this.stream.offset + this.headerCache.size;
                if (cookie = M4ADemuxer.readEsds(this.stream)) {
                  this.emit('cookie', cookie);
                }
                this.stream.seek(offset);
              } else {
                buffer = this.stream.readBuffer(this.headerCache.size);
                this.emit('cookie', buffer);
              }
              this.headerCache = null;
            }
            break;
          case 'pakt':
            if (this.stream.available(this.headerCache.size)) {
              if (this.stream.readUInt32() !== 0) {
                return this.emit('error', 'Sizes greater than 32 bits are not supported.');
              }
              this.numPackets = this.stream.readUInt32();
              if (this.stream.readUInt32() !== 0) {
                return this.emit('error', 'Sizes greater than 32 bits are not supported.');
              }
              this.numFrames = this.stream.readUInt32();
              this.primingFrames = this.stream.readUInt32();
              this.remainderFrames = this.stream.readUInt32();
              this.emit('duration', this.numFrames / this.format.sampleRate * 1000 | 0);
              this.sentDuration = true;
              byteOffset = 0;
              sampleOffset = 0;
              for (i = j = 0, ref = this.numPackets; j < ref; i = j += 1) {
                this.addSeekPoint(byteOffset, sampleOffset);
                byteOffset += this.format.bytesPerPacket || M4ADemuxer.readDescrLen(this.stream);
                sampleOffset += this.format.framesPerPacket || M4ADemuxer.readDescrLen(this.stream);
              }
              this.headerCache = null;
            }
            break;
          case 'info':
            entries = this.stream.readUInt32();
            metadata = {};
            for (i = k = 0, ref1 = entries; 0 <= ref1 ? k < ref1 : k > ref1; i = 0 <= ref1 ? ++k : --k) {
              key = this.stream.readString(null);
              value = this.stream.readString(null);
              metadata[key] = value;
            }
            this.emit('metadata', metadata);
            this.headerCache = null;
            break;
          case 'data':
            if (!this.sentFirstDataChunk) {
              this.stream.advance(4);
              this.headerCache.size -= 4;
              if (this.format.bytesPerPacket !== 0 && !this.sentDuration) {
                this.numFrames = this.headerCache.size / this.format.bytesPerPacket;
                this.emit('duration', this.numFrames / this.format.sampleRate * 1000 | 0);
              }
              this.sentFirstDataChunk = true;
            }
            buffer = this.stream.readSingleBuffer(this.headerCache.size);
            this.headerCache.size -= buffer.length;
            this.emit('data', buffer);
            if (this.headerCache.size <= 0) {
              this.headerCache = null;
            }
            break;
          default:
            if (this.stream.available(this.headerCache.size)) {
              this.stream.advance(this.headerCache.size);
              this.headerCache = null;
            }
        }
      }
    };

    return CAFDemuxer;

  })(Demuxer);

}).call(this);
