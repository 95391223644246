// Generated by CoffeeScript 1.12.7
(function() {
  var Decoder, XLAWDecoder,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  Decoder = require('../decoder');

  XLAWDecoder = (function(superClass) {
    var BIAS, QUANT_MASK, SEG_MASK, SEG_SHIFT, SIGN_BIT;

    extend(XLAWDecoder, superClass);

    function XLAWDecoder() {
      this.readChunk = bind(this.readChunk, this);
      return XLAWDecoder.__super__.constructor.apply(this, arguments);
    }

    Decoder.register('ulaw', XLAWDecoder);

    Decoder.register('alaw', XLAWDecoder);

    SIGN_BIT = 0x80;

    QUANT_MASK = 0xf;

    SEG_SHIFT = 4;

    SEG_MASK = 0x70;

    BIAS = 0x84;

    XLAWDecoder.prototype.init = function() {
      var i, j, k, seg, t, table, val;
      this.format.bitsPerChannel = 16;
      this.table = table = new Int16Array(256);
      if (this.format.formatID === 'ulaw') {
        for (i = j = 0; j < 256; i = ++j) {
          val = ~i;
          t = ((val & QUANT_MASK) << 3) + BIAS;
          t <<= (val & SEG_MASK) >>> SEG_SHIFT;
          table[i] = val & SIGN_BIT ? BIAS - t : t - BIAS;
        }
      } else {
        for (i = k = 0; k < 256; i = ++k) {
          val = i ^ 0x55;
          t = val & QUANT_MASK;
          seg = (val & SEG_MASK) >>> SEG_SHIFT;
          if (seg) {
            t = (t + t + 1 + 32) << (seg + 2);
          } else {
            t = (t + t + 1) << 3;
          }
          table[i] = val & SIGN_BIT ? t : -t;
        }
      }
    };

    XLAWDecoder.prototype.readChunk = function() {
      var i, j, output, ref, ref1, samples, stream, table;
      ref = this, stream = ref.stream, table = ref.table;
      samples = Math.min(4096, this.stream.remainingBytes());
      if (samples === 0) {
        return;
      }
      output = new Int16Array(samples);
      for (i = j = 0, ref1 = samples; j < ref1; i = j += 1) {
        output[i] = table[stream.readUInt8()];
      }
      return output;
    };

    return XLAWDecoder;

  })(Decoder);

}).call(this);
