// Generated by CoffeeScript 1.12.7
(function() {
  var Decoder, LPCMDecoder,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  Decoder = require('../decoder');

  LPCMDecoder = (function(superClass) {
    extend(LPCMDecoder, superClass);

    function LPCMDecoder() {
      this.readChunk = bind(this.readChunk, this);
      return LPCMDecoder.__super__.constructor.apply(this, arguments);
    }

    Decoder.register('lpcm', LPCMDecoder);

    LPCMDecoder.prototype.readChunk = function() {
      var chunkSize, i, j, k, l, littleEndian, m, n, o, output, ref, ref1, ref2, ref3, ref4, ref5, samples, stream;
      stream = this.stream;
      littleEndian = this.format.littleEndian;
      chunkSize = Math.min(4096, stream.remainingBytes());
      samples = chunkSize / (this.format.bitsPerChannel / 8) | 0;
      if (chunkSize < this.format.bitsPerChannel / 8) {
        return null;
      }
      if (this.format.floatingPoint) {
        switch (this.format.bitsPerChannel) {
          case 32:
            output = new Float32Array(samples);
            for (i = j = 0, ref = samples; j < ref; i = j += 1) {
              output[i] = stream.readFloat32(littleEndian);
            }
            break;
          case 64:
            output = new Float64Array(samples);
            for (i = k = 0, ref1 = samples; k < ref1; i = k += 1) {
              output[i] = stream.readFloat64(littleEndian);
            }
            break;
          default:
            throw new Error('Unsupported bit depth.');
        }
      } else {
        switch (this.format.bitsPerChannel) {
          case 8:
            output = new Int8Array(samples);
            for (i = l = 0, ref2 = samples; l < ref2; i = l += 1) {
              output[i] = stream.readInt8();
            }
            break;
          case 16:
            output = new Int16Array(samples);
            for (i = m = 0, ref3 = samples; m < ref3; i = m += 1) {
              output[i] = stream.readInt16(littleEndian);
            }
            break;
          case 24:
            output = new Int32Array(samples);
            for (i = n = 0, ref4 = samples; n < ref4; i = n += 1) {
              output[i] = stream.readInt24(littleEndian);
            }
            break;
          case 32:
            output = new Int32Array(samples);
            for (i = o = 0, ref5 = samples; o < ref5; i = o += 1) {
              output[i] = stream.readInt32(littleEndian);
            }
            break;
          default:
            throw new Error('Unsupported bit depth.');
        }
      }
      return output;
    };

    return LPCMDecoder;

  })(Decoder);

}).call(this);
