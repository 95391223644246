// Generated by CoffeeScript 1.12.7
(function() {
  var Filter, VolumeFilter,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  Filter = require('../filter');

  VolumeFilter = (function(superClass) {
    extend(VolumeFilter, superClass);

    function VolumeFilter() {
      return VolumeFilter.__super__.constructor.apply(this, arguments);
    }

    VolumeFilter.prototype.process = function(buffer) {
      var i, j, ref, vol;
      if (this.value >= 100) {
        return;
      }
      vol = Math.max(0, Math.min(100, this.value)) / 100;
      for (i = j = 0, ref = buffer.length; j < ref; i = j += 1) {
        buffer[i] *= vol;
      }
    };

    return VolumeFilter;

  })(Filter);

  module.exports = VolumeFilter;

}).call(this);
