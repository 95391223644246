// Generated by CoffeeScript 1.12.7
(function() {
  var AVBuffer, BufferList, BufferSource, EventEmitter,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  EventEmitter = require('../core/events');

  BufferList = require('../core/bufferlist');

  AVBuffer = require('../core/buffer');

  BufferSource = (function(superClass) {
    var clearImmediate, setImmediate;

    extend(BufferSource, superClass);

    function BufferSource(input) {
      this.loop = bind(this.loop, this);
      if (input instanceof BufferList) {
        this.list = input;
      } else {
        this.list = new BufferList;
        this.list.append(new AVBuffer(input));
      }
      this.paused = true;
    }

    setImmediate = global.setImmediate || function(fn) {
      return global.setTimeout(fn, 0);
    };

    clearImmediate = global.clearImmediate || function(timer) {
      return global.clearTimeout(timer);
    };

    BufferSource.prototype.start = function() {
      this.paused = false;
      return this._timer = setImmediate(this.loop);
    };

    BufferSource.prototype.loop = function() {
      this.emit('progress', (this.list.numBuffers - this.list.availableBuffers + 1) / this.list.numBuffers * 100 | 0);
      this.emit('data', this.list.first);
      if (this.list.advance()) {
        return setImmediate(this.loop);
      } else {
        return this.emit('end');
      }
    };

    BufferSource.prototype.pause = function() {
      clearImmediate(this._timer);
      return this.paused = true;
    };

    BufferSource.prototype.reset = function() {
      this.pause();
      return this.list.rewind();
    };

    return BufferSource;

  })(EventEmitter);

  module.exports = BufferSource;

}).call(this);
