// Generated by CoffeeScript 1.12.7
(function() {
  var EventEmitter, Queue,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  EventEmitter = require('./core/events');

  Queue = (function(superClass) {
    extend(Queue, superClass);

    function Queue(asset) {
      this.asset = asset;
      this.write = bind(this.write, this);
      this.readyMark = 64;
      this.finished = false;
      this.buffering = true;
      this.ended = false;
      this.buffers = [];
      this.asset.on('data', this.write);
      this.asset.on('end', (function(_this) {
        return function() {
          return _this.ended = true;
        };
      })(this));
      this.asset.decodePacket();
    }

    Queue.prototype.write = function(buffer) {
      if (buffer) {
        this.buffers.push(buffer);
      }
      if (this.buffering) {
        if (this.buffers.length >= this.readyMark || this.ended) {
          this.buffering = false;
          return this.emit('ready');
        } else {
          return this.asset.decodePacket();
        }
      }
    };

    Queue.prototype.read = function() {
      if (this.buffers.length === 0) {
        return null;
      }
      this.asset.decodePacket();
      return this.buffers.shift();
    };

    Queue.prototype.reset = function() {
      this.buffers.length = 0;
      this.buffering = true;
      return this.asset.decodePacket();
    };

    return Queue;

  })(EventEmitter);

  module.exports = Queue;

}).call(this);
