// Generated by CoffeeScript 1.12.7
(function() {
  var UnderflowError,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  UnderflowError = (function(superClass) {
    extend(UnderflowError, superClass);

    function UnderflowError() {
      UnderflowError.__super__.constructor.apply(this, arguments);
      this.name = 'UnderflowError';
      this.stack = new Error().stack;
    }

    return UnderflowError;

  })(Error);

  module.exports = UnderflowError;

}).call(this);
