// Generated by CoffeeScript 1.12.7
(function() {
  var AudioDevice, EventEmitter,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  EventEmitter = require('./core/events');

  AudioDevice = (function(superClass) {
    var devices;

    extend(AudioDevice, superClass);

    function AudioDevice(sampleRate1, channels1) {
      this.sampleRate = sampleRate1;
      this.channels = channels1;
      this.updateTime = bind(this.updateTime, this);
      this.playing = false;
      this.currentTime = 0;
      this._lastTime = 0;
    }

    AudioDevice.prototype.start = function() {
      if (this.playing) {
        return;
      }
      this.playing = true;
      if (this.device == null) {
        this.device = AudioDevice.create(this.sampleRate, this.channels);
      }
      if (!this.device) {
        throw new Error("No supported audio device found.");
      }
      this._lastTime = this.device.getDeviceTime();
      this._timer = setInterval(this.updateTime, 200);
      return this.device.on('refill', this.refill = (function(_this) {
        return function(buffer) {
          return _this.emit('refill', buffer);
        };
      })(this));
    };

    AudioDevice.prototype.stop = function() {
      if (!this.playing) {
        return;
      }
      this.playing = false;
      this.device.off('refill', this.refill);
      return clearInterval(this._timer);
    };

    AudioDevice.prototype.destroy = function() {
      var ref;
      this.stop();
      return (ref = this.device) != null ? ref.destroy() : void 0;
    };

    AudioDevice.prototype.seek = function(currentTime) {
      this.currentTime = currentTime;
      if (this.playing) {
        this._lastTime = this.device.getDeviceTime();
      }
      return this.emit('timeUpdate', this.currentTime);
    };

    AudioDevice.prototype.updateTime = function() {
      var time;
      time = this.device.getDeviceTime();
      this.currentTime += (time - this._lastTime) / this.device.sampleRate * 1000 | 0;
      this._lastTime = time;
      return this.emit('timeUpdate', this.currentTime);
    };

    devices = [];

    AudioDevice.register = function(device) {
      return devices.push(device);
    };

    AudioDevice.create = function(sampleRate, channels) {
      var device, i, len;
      for (i = 0, len = devices.length; i < len; i++) {
        device = devices[i];
        if (device.supported) {
          return new device(sampleRate, channels);
        }
      }
      return null;
    };

    return AudioDevice;

  })(EventEmitter);

  module.exports = AudioDevice;

}).call(this);
