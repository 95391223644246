// Generated by CoffeeScript 1.12.7
(function() {
  var Base, EventEmitter,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  Base = require('./base');

  EventEmitter = (function(superClass) {
    extend(EventEmitter, superClass);

    function EventEmitter() {
      return EventEmitter.__super__.constructor.apply(this, arguments);
    }

    EventEmitter.prototype.on = function(event, fn) {
      var base;
      if (this.events == null) {
        this.events = {};
      }
      if ((base = this.events)[event] == null) {
        base[event] = [];
      }
      return this.events[event].push(fn);
    };

    EventEmitter.prototype.off = function(event, fn) {
      var events, index, ref;
      if (this.events == null) {
        return;
      }
      if ((ref = this.events) != null ? ref[event] : void 0) {
        if (fn != null) {
          index = this.events[event].indexOf(fn);
          if (~index) {
            return this.events[event].splice(index, 1);
          }
        } else {
          return this.events[event];
        }
      } else if (event == null) {
        return events = {};
      }
    };

    EventEmitter.prototype.once = function(event, fn) {
      var cb;
      return this.on(event, cb = function() {
        this.off(event, cb);
        return fn.apply(this, arguments);
      });
    };

    EventEmitter.prototype.emit = function() {
      var args, event, fn, i, len, ref, ref1;
      event = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
      if (!((ref = this.events) != null ? ref[event] : void 0)) {
        return;
      }
      ref1 = this.events[event].slice();
      for (i = 0, len = ref1.length; i < len; i++) {
        fn = ref1[i];
        fn.apply(this, args);
      }
    };

    return EventEmitter;

  })(Base);

  module.exports = EventEmitter;

}).call(this);
